export const images = {
  logo: require("../assets/images/smec.png").default,
  logo_kerala: require("../assets/images/logo-kerala1.jpeg").default,
  logo_cloudsynergy: require("../assets/images/logo-cloudsynergy.png").default,
  logo_apponix: require("../assets/images/logo-apponix.svg").default,
  logo_gloedge: require("../assets/images/logo-gloedge.png").default,
  logo_educba: require("../assets/images/logo-educba.png").default,
  logo_itmentor: require("../assets/images/logo-IT-Mentor.png").default,
  logo_vtechintegratedsolutions: require("../assets/images/logo-vtech.png").default,
  logo_rogersoft: require("../assets/images/logo-rogersoft.png").default,
  logo_smeclabs: require("../assets/images/logo-smeclabs.png").default,
  logo_Aptech: require("../assets/images/logo-aptech-new.png").default,
  logo_Rpsconsulting: require("../assets/images/logo-rps.png").default,
  logo_Xploreitcorp: require("../assets/images/logo-xplore-it-corp.png").default,
  logo_Techolas: require("../assets/images/logo-techolas.png").default,
  logo_Bytesinbits: require("../assets/images/logo-bytesinbits.webp").default,
  logo_Parinitha: require("../assets/images/logo-parinitha.png").default,
  logo_Jetking: require("../assets/images/logo-jetking.webp").default,
  logo_Lnteks: require("../assets/images/logo-lnteks.png").default,
  logo_busi: require("../assets/images/smec.png").default,
  logo_white: require("../assets/images/logo-white.svg").default,
  logo_footer: require("../assets/images/logo-footer.svg").default,
  logo_small: require("../assets/images/logo-small.svg").default,
  user: require("../assets/images/user-not-found.svg").default,
  bg: require("../assets/images/bg-sharp.svg").default,
  shape: require("../assets/images/shape.svg").default,
  notification: require("../assets/images/notification.svg").default,
  notificationimg: require("../assets/images/notification-img.svg").default,
  notifiactive: require("../assets/images/notification-active.svg").default,
  update: require("../assets/images/update-icon.svg").default,
  upload_img: require("../assets/images/upload-img.svg").default,
  slack: require("../assets/images/slack-icon.svg").default,

  jplogo: require("../assets/images/jp-logo.png").default,
  jplogo: require("../assets/images/jp-logo.png").default,
  accenture: require("../assets/images/logo-accenture.svg").default,
  infosys: require("../assets/images/logo-infosys.svg").default,
  campgemni: require("../assets/images/logo-campgemni.svg").default,
  deloitte: require("../assets/images/logo-deloitte.svg").default,
  telefonica: require("../assets/images/telefonica-logo.png").default,
  tuv: require("../assets/images/TUV-logo.png").default,
  gkl: require("../assets/images/global-knowledge-logo.png").default,
  wl: require("../assets/images/wavestone-logo.png").default,
  vcl: require("../assets/images/vivid-cloud-logo.png").default,
  tlg: require("../assets/images/TLG-logo.png").default,
  miami: require("../assets/images/miami-logo.png").default,
  versor: require("../assets/images/versor-logo.png").default,
  mityo: require("../assets/images/mityo-logo.png").default,
  stack: require("../assets/images/stack-logo.png").default,
  digi: require("../assets/images/digi-logo.png").default,


  fidelity: require("../assets/images/logo-fidelity.svg").default,
  sopra: require("../assets/images/logo-sopra-steria.svg").default,
  globel: require("../assets/images/logo-globel-knowladge.svg").default,
  airforce: require("../assets/images/logo-us-airforce.svg").default,
  wave: require("../assets/images/logo-wave-stone.svg").default,
  miami: require("../assets/images/logo-miami-dade.svg").default,
  pwc: require("../assets/images/logo-pwc.svg").default,
  parker: require("../assets/images/logo-parker.svg").default,
  practice: require("../assets/images/practice-exam.svg").default,
  console: require("../assets/images/console-access.svg").default,
  hand: require("../assets/images/hand-on-labs.svg").default,
  globelemp: require("../assets/images/globel-employee@2x.png").default,
  cloud: require("../assets/images/cloud.svg").default,
  devops: require("../assets/images/devops.svg").default,
  javaimg: require("../assets/images/java.svg").default,
  bigdata: require("../assets/images/bigdata.svg").default,
  chain: require("../assets/images/block-chain.svg").default,
  mngement: require("../assets/images/projet-mngement.svg").default,
  feature1: require("../assets/images/whiz-business-feature1@2x.png").default,
  feature2: require("../assets/images/whiz-business-feature2@2x.png").default,
  banner_img: require("../assets/images/whiz-business-banner@2x.png").default,
  right_img: require("../assets/images/right-green-img.svg").default,

  ansible: require("../assets/images/Ansible-course@2x.png").default,
  awl: require("../assets/images/Aws-course@2x.png").default,
  google: require("../assets/images/Google-Cloud@2x.png").default,
  java: require("../assets/images/Java-course@2x.png").default,
  lambda: require("../assets/images/Lambda-course@2x.png").default,

  learning_img: require("../assets/images/Learning-Path.svg").default,
  training_img: require("../assets/images/training-img.png").default,
  bundle_img: require("../assets/images/bundle-img.png").default,
  team_img: require("../assets/images/Team.svg").default,
  user_img: require("../assets/images/user-not-found.svg").default,
  course_color: require("../assets/images/course-color.svg").default,
  training_color: require("../assets/images/training-color.svg").default,
  subs_color: require("../assets/images/subscription-color.svg").default,
  learning_color: require("../assets/images/learning-color.svg").default,

  tomas: require("../assets/images/tomas.png").default,
  location: require("../assets/images/location.svg").default,
  learners11: require("../assets/images/learners11.png").default,
  learners12: require("../assets/images/learners12.png").default,
  learners13: require("../assets/images/learners13.png").default,
  learners21: require("../assets/images/learners21.png").default,
  learners22: require("../assets/images/learners22.png").default,
  learners23: require("../assets/images/learners23.png").default,
  activities1: require("../assets/images/activities1.png").default,
  activities2: require("../assets/images/activities2.png").default,
  activities3: require("../assets/images/activities3.png").default,
  activities4: require("../assets/images/activities4.png").default,
  activities5: require("../assets/images/activities5.png").default,
  activities6: require("../assets/images/activities6.png").default,
  awscertified: require("../assets/images/aws-certified.jpg").default,
  tomasuser: require("../assets/images/tomas-user.jpg").default,
  brochurePdfFile: require("../assets/brochure/Whizlabs_Business_Competency_Plus.pdf").default,
  upskill1: require("../assets/images/upskill-1.png").default,
  upskill2: require("../assets/images/upskill-2.png").default,
  upskill3: require("../assets/images/upskill-3.png").default,
  bannerwhite: require("../assets/images/white-light.png").default,
  whiteSetting: require("../assets/images/white-setting.png").default,
  whitePlay: require("../assets/images/white-play.png").default,
  learner1: require("../assets/images/Casey.png").default,
  learner2: require("../assets/images/daniel.png").default,
  learner3: require("../assets/images/karthik.png").default,
  learner4: require("../assets/images/krishna.png").default,
  playborder: require("../assets/images/play-border.png").default,
  support: require("../assets/images/support.svg").default,
  anytime: require("../assets/images/anytime.svg").default,
  analytics: require("../assets/images/analytics.svg").default,
  sandboxright: require("../assets/images/sandboxes-right.png").default,
  globalright: require("../assets/images/globel-right-img.png").default,
  globalmap: require("../assets/images/globel-map.png").default,
  googleplay: require("../assets/images/google-play.svg").default,
  appstore: require("../assets/images/app-store.svg").default,
  youtube: require("../assets/images/youtube.svg").default,
  visa: require("../assets/images/visabg.svg").default,
  mastercard: require("../assets/images/mastercardbg.svg").default,
  mailicon: require("../assets/images/email.png").default,
  whatsappicon: require("../assets/images/whatsapp.png").default,
  brochureicon: require("../assets/images/brochure.png").default,
};
