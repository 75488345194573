import React, { Suspense, useEffect, useState }  from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { renderRoutes, privateRenderRoutes } from "./config/routes";
import "./common.scss";
import PrivateRoute from './PrivateRoute'
import Cookie from "js-cookie";
import { images } from "src/config/images";
// import { getUserProfile } from "src/services/users-services/services.tsx";
import ReactLoading from 'react-loading';
import { CheckPrivileges } from './PermissionContexts.js';
import { CheckDomainDetail } from "src/config/utils";
const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  const domainAccessInfo = JSON.parse(localStorage.getItem('domainAccessInfo'));
  let loaderColor = (domainAccessInfo?.companyInfo?.button_color)?domainAccessInfo?.companyInfo?.button_color:'#ff953f';
  useEffect(() => {
    setLoading(false);
  }, []);
  CheckDomainDetail();
  useEffect(() => {
    if (domainAccessInfo?.companyInfo?.allow_whitelabeling){
      
      if(window.location.pathname === '/'){
        window.location.href = `/login`
      } else {
        document.documentElement.style.setProperty('--orange',domainAccessInfo?.companyInfo?.button_color);
        document.documentElement.style.setProperty('--hovere05613',domainAccessInfo?.companyInfo?.button_color);
        document.documentElement.style.setProperty('--input_border',domainAccessInfo?.companyInfo?.input_border);
        document.documentElement.style.setProperty('--input_border_shadow',"0 0 0 4px "+domainAccessInfo?.companyInfo?.input_border_shadow);
        document.documentElement.style.setProperty('--orangef16521',domainAccessInfo?.companyInfo?.sidebar_link_color);
        document.documentElement.style.setProperty('--orangef16c2a',domainAccessInfo?.companyInfo?.sidebar_link_color);
        document.documentElement.style.setProperty('--orangefff0e2',domainAccessInfo?.companyInfo?.sidebar_link_background);
        document.documentElement.style.setProperty('--copyrightText',"ujash");
      }
    }
  }, [domainAccessInfo]);

  const clearSession = () => {
    localStorage.clear();
    // Cookie.remove("cartId");
    // Cookie.remove("cartData");
    Cookie.remove("is_socialLogin");
    Cookie.remove("laravel_session");
    Cookie.remove("whizlabs_session");
    Cookie.remove("beta_block");

    Cookie.remove("userData");
    Cookie.remove("userData", { path: "/", domain: ".whizlabs.org" });
    Cookie.remove("userData", { path: "/", domain: ".whizlabs.com" });
  };

  return (
    !loading && (
      <div className="App">
           <Suspense fallback={<div style={
            {
              position: "absolute",
              top: "50%",
              left: "50%",}
           }>
             <ReactLoading type={'spin'} color={loaderColor} height={60} width={60} />
           </div>
          }>
          <BrowserRouter>
            <Switch>
              {renderRoutes.map(([key, route]) => (
                <Route
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              <CheckPrivileges>
              {privateRenderRoutes.map(([key, route]) => (
                <PrivateRoute
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              </CheckPrivileges>
            </Switch>
          </BrowserRouter>
        </Suspense>
      </div>
    )
  );
}

export default App;
