import { lazy } from "react";

export const routes = {
  blank_page: {
    path: "/blank-page",
    component: lazy(() => import("../pages/BlankPage/BlankPage")),
    exact: true,
  },
  index: {
    path: "/",
    //component: lazy(() => import("../pages/IndexPage/IndexPage")),
    component: lazy(() => import("../pages/LoginPage/LoginPage")),
    exact: true,
  },
  request_demo: {
    path: "/request-demo-page",
    component: lazy(() => import("../pages/RequestDemoPage/RequestDemoPage")),
    exact: true,
  },
  request_demo_visa: {
    path: "/visa/request-demo-page",
    component: lazy(() => import("../pages/RequestDemoPage/RequestDemoPagePromote")),
    exact: true,
  },
  request_demo_mastercard: {
    path: "/mastercard/request-demo-page",
    component: lazy(() => import("../pages/RequestDemoPage/RequestDemoPagePromote")),
    exact: true,
  },
  saml: {
    path: "/saml/accops/:token?",
    component: lazy(() => import("../pages/SamlLoginPage/SamlLoginPage")),
    exact: true,
  },
  login_page: {
    path: "/login",
    component: lazy(() => import("../pages/LoginPage/LoginPage")),
    exact: true,
  },
  login_page_keralasteds: {
    path: "/login/keralasteds",
    component: lazy(() => import("../pages/LoginPageKeralasteds/LoginPageKeralasteds")),
    exact: true,
  },
  login_page_educba: {
    path: "/login/educba",
    component: lazy(() => import("../pages/LoginPageEducba/LoginPageEducba")),
    exact: true,
  },
  login_page_itmentor: {
    path: "/login/itmentor",
    component: lazy(() => import("../pages/LoginPageItmentor/LoginPageItmentor")),
    exact: true,
  },
  login_page_vtechintegratedsolutions: {
    path: "/login/vtech",
    component: lazy(() => import("../pages/LoginPageVtechintegratedsolutions/LoginPageVtechintegratedsolutions")),
    exact: true,
  },
  login_page_rogersoft: {
    path: "/login/rogersoft",
    component: lazy(() => import("../pages/LoginPageRogersoft/LoginPageRogersoft")),
    exact: true,
  },
  login_page_smeclabs: {
    path: "/login/smeclabs",
    component: lazy(() => import("../pages/LoginPageSmeclabs/LoginPageSmeclabs")),
    exact: true,
  },
  login_page_cloudsynergy: {
    path: "/login/cloudsynergy",
    component: lazy(() => import("../pages/LoginPageCloudsynergy/LoginPageCloudsynergy")),
    exact: true,
  },
  login_page_apponix: {
    path: "/login/apponix",
    component: lazy(() => import("../pages/LoginPageApponix/LoginPageApponix")),
    exact: true,
  },
  login_page_gloedge: {
    path: "/login/gloedge",
    component: lazy(() => import("../pages/LoginPageGloedge/LoginPageGloedge")),
    exact: true,
  },
  login_page_aptech: {
    path: "/login/aptech",
    component: lazy(() => import("../pages/LoginPageAptech/LoginPageAptech")),
    exact: true,
  },
  login_page_rpsconsulting: {
    path: "/login/rpsconsulting",
    component: lazy(() => import("../pages/LoginPageRpsconsulting/LoginPageRpsconsulting")),
    exact: true,
  },
  login_page_xploreitcorp: {
    path: "/login/xploreitcorp",
    component: lazy(() => import("../pages/LoginPageXploreitcorp/LoginPageXploreitcorp")),
    exact: true,
  },
  login_page_techolas: {
    path: "/login/techolas",
    component: lazy(() => import("../pages/LoginPageTecholas/LoginPageTecholas")),
    exact: true,
  },
  login_page_bytesinbits: {
    path: "/login/bytesinbits",
    component: lazy(() => import("../pages/LoginPageBytesinbits/LoginPageBytesinbits")),
    exact: true,
  },
  login_page_parinitha: {
    path: "/login/parinitha",
    component: lazy(() => import("../pages/LoginPageParinitha/LoginPageParinitha")),
    exact: true,
  },
  login_page_jetking: {
    path: "/login/jetking",
    component: lazy(() => import("../pages/LoginPageJetking/LoginPageJetking")),
    exact: true,
  },
  login_page_lnteks: {
    path: "/login/lnteks",
    component: lazy(() => import("../pages/LoginPageLnteks/LoginPageLnteks")),
    exact: true,
  },
  forget_page: {
    path: "/forgot-password",
    component: lazy(() =>
      import("../pages/ForgetPassWordPage/ForgetPassWordPage")
    ),
    exact: true,
  },
  reset_password: {
    path: "/password/reset",
    component: lazy(() => import("../pages/ResetPassword/ResetPassword")),
    exact: true,
  },
  sso_signin: {
    path : "/sso/:token/:id",
    component: lazy(() => import("../pages/ssoSigin/ssosignin")),
    exact: true,
  },
};

export const renderRoutes = Object.entries(routes);

export const privateroutes = {
  dashboard: {
    path: "/dashboard",
    component: lazy(() => import("../pages/Dashboard/Dashboard")),
    exact: true,
    menuname: 'Dashboard'
  },
  teams: {
    path: "/teams",
    component: lazy(() => import("../pages/Teams/Teams")),
    exact: true,
    menuname: 'Teams'
  },
  create_team: {
    path: "/create-team",
    component: lazy(() => import("../pages/CreateTeam/CreateTeam")),
    exact: true,
    menuname: 'Teams'
  },
  Edit_team: {
    path: "/edit-team/:id",
    component: lazy(() => import("../pages/EditTeam/EditTeam")),
    exact: true,
    menuname: 'Teams'
  },
  Team_Report: {
    path: "/team-report/:id",
    component: lazy(() => import("../pages/TeamReport/TeamReport")),
    exact: true,
    menuname: 'Teams'
  },
  users: {
    path: "/users",
    component: lazy(() => import("../pages/Users/Users")),
    exact: true,
    menuname: 'Users'
  },
  user_quick_view: {
    path: "/user-quick-view/:id",
    component: lazy(() => import("../pages/UserQuickView/UserQuickView")),
    exact: true,
    menuname: 'Users'
  },
  add_user: {
    path: "/add-user",
    component: lazy(() => import("../pages/AddUser/AddUser")),
    exact: true,
    menuname: 'Users'
  },
  edit_user: {
    path: "/edit-user/:id",
    component: lazy(() => import("../pages/EditUser/EditUser")),
    exact: true,
    menuname: 'Users'
  },
  courses: {
    path: "/courses",
    component: lazy(() => import("../pages/Courses/Courses")),
    exact: true,
    menuname: 'Courses'
  },
  subscriptions: {
    path: "/subscriptions",
    component: lazy(() => import("../pages/Subscriptions/Subcriptions")),
    exact: true,
    menuname: 'Subscriptions'
  },
  courses_select: {
    path: "/courses-select",
    component: lazy(() => import("../pages/CourseSelect/CourseSelect")),
    exact: true,
    menuname: 'Courses'
  },
  learning: {
    path: "/learning",
    component: lazy(() => import("../pages/Learning/Learning")),
    exact: true,
    menuname: 'Learning Path'
  },
  learning_select: {
    path: "/learning-select",
    component: lazy(() => import("../pages/LearningSelect/LearningSelect")),
    exact: true,
    menuname: 'Learning Path'
  },
  create_learning: {
    path: "/create-learning",
    component: lazy(() => import("../pages/CreateLearning/CreateLearning")),
    exact: true,
    menuname: 'Learning Path'
  },
  edit_learning: {
    path: "/edit-learning/:id",
    component: lazy(() => import("../pages/EditLearning/EditLearning")),
    exact: true,
    menuname: 'Learning Path'
  },
  view_report_learning: {
    path: "/learning/view-report/:id",
    component: lazy(() => import("../pages/LearningReport/LearningReport")),
    exact: true,
    menuname: 'Learning Path'
  },
  training: {
    path: "/training",
    component: lazy(() => import("../pages/Training/Training")),
    exact: true,
    menuname: 'Training'
  },
  training_select: {
    path: "/training-select",
    component: lazy(() => import("../pages/TrainingSelect/TrainingSelect")),
    exact: true,
    menuname: 'Training'
  },
  create_training: {
    path: "/create-training",
    component: lazy(() => import("../pages/CreateTraining/CreateTraining")),
    exact: true,
    menuname: 'Training'
  },
  duplicate_copy_training: {
    path: "/duplicate-training",
    component: lazy(() =>
      import("../pages/DuplicateTraining/DuplicateTraining")
    ),
    exact: true,
    menuname: 'Training'
  },
  roles_privileges: {
    path: "/roles-privileges",
    component: lazy(() => import("../pages/RolesPrivileges/RolesPrivileges")),
    exact: true,
    menuname: 'Roles'
  },
  add_roles_privileges: {
    path: "/add-roles-privileges",
    component: lazy(() => import("../pages/AddRoles/AddRoles")),
    exact: true,
    menuname: 'Roles'
  },
  edit_roles_privileges: {
    path: "/edit-roles-privileges/:id",
    component: lazy(() => import("../pages/EditRoles/EditRoles")),
    exact: true,
    menuname: 'Roles'
  },
  inbox: {
    path: "/inbox",
    component: lazy(() => import("../pages/Inbox/Inbox")),
    exact: true,
    menuname: 'Indox'
  },
  inbox_view: {
    path: "/inbox-view",
    component: lazy(() => import("../pages/InboxView/InboxView")),
    exact: true,
    menuname: 'Indox'
  },
  email_notification: {
    path: "/email-notification",
    component: lazy(() => import("../pages/EmailNotification/EmailPreferences")),
    exact: true,
    menuname: 'Email Notification'
  },
  setting_page: {
    path: "/setting-page",
    component: lazy(() => import("../pages/SettingPage/SettingPage")),
    exact: true,
    menuname: 'Settings'
  },
  support_page: {
    path: "/support-page",
    component: lazy(() => import("../pages/SupportPage/Support")),
    exact: true,
    menuname: 'Support'
  },
  practice_test_page: {
    path: "/reports/practice-test",
    component: lazy(() => import("../pages/ReportsPage/PracticeTest")),
    exact: true,
    menuname: 'Analytics'
  },
  live_lab_report_page: {
    path: "/reports/live-labs",
    component: lazy(() => import("../pages/ReportsPage/HandsOnLabReport")),
    exact: true,
    menuname: 'Analytics'
  },
  user_lab_behaviour_report_page: {
    path: "/reports/lab-behaviour-report",
    component: lazy(() => import("../pages/UserBehaviourLabReport/UserBehaviourLabReport")),
    exact: true,
    menuname: 'Analytics'
  },
  user_sandbox_behaviour_report_page: {
    path: "/reports/sandbox-behaviour-report",
    component: lazy(() => import("../pages/UserBehaviourSandboxReport/UserBehaviourSandboxReport")),
    exact: true,
    menuname: 'Analytics'
  },
  live_lab_report_dashboard: {
    path: "/reports/live-labs-dashboard",
    component: lazy(() => import("../pages/LiveLabsDashboard/LiveLabsDashboard")),
    exact: true,
    menuname: 'Analytics'
  },
  live_sandbox_report_dashboard: {
    path: "/reports/sandbox-dashboard",
    component: lazy(() => import("../pages/LiveSandboxDashboard/LiveSandboxDashboard")),
    exact: true,
    menuname: 'Analytics'
  },
  sandbox_report_page: {
    path: "/reports/sandbox",
    component: lazy(() => import("../pages/ReportsPage/SandboxReport")),
    exact: true,
    menuname: 'Analytics'
  },
  online_test_page: {
    path: "/reports/online-test",
    component: lazy(() => import("../pages/ReportsPage/OnlineTest")),
    exact: true,
    menuname: 'Analytics'
  },
  Enrollment_report_page: {
    path: "/reports/enrollment-report",
    component: lazy(() => import("../pages/ReportsPage/EnrollmentReport")),
    exact: true,
    menuname: 'Analytics'
  },
  Certificate_report_page: {
    path: "/reports/certificate-report",
    component: lazy(() => import("../pages/ReportsPage/CertificateReport")),
    exact: true,
    menuname: 'Analytics'
  },
  // <---- user-pages ----->
  home_user: {
    path: "/home-user",
    component: lazy(() =>
      import("../pages/UserPages/HomeUserPage/HomeUserPage")
    ),
    exact: true,
    menuname: 'Dashboard'
  },
  courses_user: {
    path: "/courses-user-page",
    component: lazy(() =>
      import("../pages/UserPages/CoursesUserPage/CoursesUserPage")
    ),
    exact: true,
    menuname: 'Courses'
  },
  courses_user_requested: {
    path: "/requested-courses",
    component: lazy(() =>
      import("../pages/UserPages/RequestedCourses/RequestedCourses")
    ),
    exact: true,
    menuname: 'Courses'
  },
  inbox_user: {
    path: "/inbox-user-page",
    component: lazy(() =>
      import("../pages/UserPages/InboxUserPage/InboxUserPage")
    ),
    exact: true,
    menuname: 'Inbox'
  },
  inbox_user_view: {
    path: "/inbox-user-view",
    component: lazy(() =>
      import("../pages/UserPages/InboxUserViewPage/InboxUserViewPage")
    ),
    exact: true,
    menuname: 'Inbox'
  },
  setting_user_page: {
    path: "/setting-user-page",
    component: lazy(() =>
      import("../pages/UserPages/SettingUserPage/SettingUserPage")
    ),
    exact: true,
    menuname: 'Setting Page'
  },
  superior_user_page: {
    path: "/superior-user-page",
    component: lazy(() =>
      import("../pages/UserPages/SuperiorUserPage/SuperiorUserPage")
    ),
    exact: true,
    menuname: 'Superior Page'
  },
  notification_user_page: {
    path: "/notification-user-page",
    component: lazy(() =>
      import("../pages/UserPages/NotificationUserPage/NotificationUserPage")
    ),
    exact: true,
    menuname: 'Notification'
  },
  group_chat_user: {
    path: "/group-chat-user",
    component: lazy(() =>
      import("../pages/UserPages/GroupChatUserPage/GroupChatUserPage")
    ),
    exact: true,
    menuname: 'Group'
  },
  my_training_user: {
    path: "/my-training-user",
    component: lazy(() =>
      import("../pages/UserPages/MyTrainingUserPage/MyTrainingUserPage")
    ),
    exact: true,
    menuname: 'My Training'
  },
  learning_paths_user: {
    path: "/learning-paths-user/:path_id",
    component: lazy(() =>
      import("../pages/UserPages/LearningPathsUser/LearningPathsUser")
    ),
    exact: true,
    menuname: 'Learning Path'
  },
  training_plans_user: {
    path: "/training-plans-user",
    component: lazy(() =>
      import("../pages/UserPages/TrainingPlansUserPage/TrainingPlansUserPage")
    ),
    exact: true,
    menuname: 'Training'
  },
  subscription_bundle_user: {
    path: "/subscription-bundle-user/:plan_id",
    component: lazy(() =>
      import(
        "../pages/UserPages/SubscriptionBundleUserPage/SubscriptionBundleUserPage"
      )
    ),
    exact: true,
    menuname: 'Subscriptions'
  },
};

export const privateRenderRoutes = Object.entries(privateroutes);
